
import React from 'react';
import ReactGA from 'react-ga';
import { Container, Jumbotron, Button, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Outages from './Outages';

//import { useAuth } from "../contexts/FirebaseAuth"
// Dummy button data and links
const buttonsData = [
    // { id: 1, name: 'View Networks', link: '/networks' },
    { id: 2, name: 'Monitors', link: '/monitors' },
    { id: 3, name: 'Devices', link: '/devices' },
    { id: 4, name: 'Clients', link: '/clients' },
    { id: 5, name: 'Printers', link: '/printers' },
    { id: 6, name: 'APC UPS', link: '/ups' }
    //{ id: 7, name: 'AV Rooms', link: '/av-rooms' }
    // Add more buttons here as needed
];

const monitorsData = [
    // { id: 1, name: 'View Networks', link: '/networks' },
    { id: 2, name: 'East Coast', link: 'https://sites.fonglellow.com/status/coast-east' },
    { id: 3, name: 'West Coast', link: 'https://sites.fonglellow.com/status/coast-west' }
    // Add more buttons here as needed
];
export default function Home() {
    const history = useHistory();
    // function to handle click events, now with navigation (example)
    const handleClick = (link) => {
        ReactGA.event({ category: "Button", action: "Click", label: link });
        history.push(link);
        // Implement navigation logic here, for example using useHistory from react-router-dom
    };


    return (
        <>
            <Container Fluid>
                <Row className="mb-2">
                    <Col xs={12} md={12} xl={4}>
                        <h2 className="text-center">Hello, Longfellow REP!&nbsp;<i class="bi bi-award-fill text-light d-none"></i></h2>
                    </Col>
                    <Col xs={12} md={12} xl={8} className="desktop-only">
                        <p className="lead text-right">Welcome, to <b>P</b>roperties, <b>B</b>uildings, and <b>R</b>ooms...</p>
                    </Col>
                </Row>
                {/* <h1>Home</h1> */}

                <Jumbotron className="bg-warning bg-gradient shadow-sm text-dark">
                    <h2>View Network Info&nbsp;<i class="bi bi-router text-light"></i></h2>
                    <p style={{ display: "none" }}>
                        This is a simple hero unit, a simple jumbotron-style component for calling
                        extra attention to featured content or information.
                    </p>
                    <Row xs={1} md={2} lg={4} className="g-4">
                        {buttonsData.map((button) => (
                            <Col xs={6} md={4} xl={2} key={button.id}>
                                <Button
                                    variant="dark"
                                    onClick={() => handleClick(button.link)}
                                    className="shadow w-100 my-2 py-2"
                                >
                                    {button.name}
                                </Button>
                            </Col>
                        ))}
                    </Row>
                </Jumbotron>

                <Jumbotron className="bg-info bg-gradient shadow-sm text-dark">
                    <h2>View UpTime Monitors&nbsp;<i class="bi bi-activity text-light"></i></h2>
                    <p style={{ display: "none" }}>
                        This is a simple hero unit, a simple jumbotron-style component for calling
                        extra attention to featured content or information.
                    </p>
                    <Row xs={1} md={2} lg={4} className="g-4">
                        <Col xs={6} md={4} xl={2}>
                            <Button
                                variant="dark"
                                className="shadow w-100 my-2 py-2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(`https://sites.fonglellow.com/status/coast-east`, '_blank');
                                }}>
                                East Coast
                            </Button>
                        </Col>
                        <Col xs={6} md={4} xl={2}>
                            <Button
                                variant="dark"
                                className="shadow w-100 my-2 py-2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(`https://sites.fonglellow.com/status/coast-west`, '_blank');
                                }}>
                                West Coast
                            </Button>
                        </Col>
                        <Col xs={6} md={4} xl={2}>
                            <Button
                                variant="dark"
                                className="shadow w-100 my-2 py-2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(`https://rooms.fonglellow.com/status/printers`, '_blank');
                                }}>
                                Office Printers
                            </Button>
                        </Col>
                        <Col xs={6} md={4} xl={2}>
                            <Button
                                variant="dark"
                                className="shadow w-100 my-2 py-2 disabled"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(`https://rooms.fonglellow.com/status/printers`, '_blank');
                                }}>
                                Clickshare
                            </Button>
                        </Col>
                        <Col xs={6} md={4} xl={2}>
                            <Button
                                variant="dark"
                                className="shadow w-100 my-2 py-2 disabled"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(`https://rooms.fonglellow.com/status/printers`, '_blank');
                                }}>
                                AirTame
                            </Button>
                        </Col>
                        <Col xs={6} md={4} xl={2}>
                            <Button
                                variant="dark"
                                className="shadow w-100 my-2 py-2 disabled"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(`https://rooms.fonglellow.com/status/printers`, '_blank');
                                }}>
                                BAS / BMS
                            </Button>
                        </Col>
                    </Row>
                </Jumbotron>

                <Row>
                    <Col>
                        <Outages />
                    </Col>
                </Row>
            </Container>

        </>
    )
}
