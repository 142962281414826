import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const portStyle = {
    width: "40px",
    height: "50px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    justifyContent: "center",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0.25rem",
};

const poeStyle = {
    position: "absolute",
    top: "-3px",
    right: "20px",
    color: "yellow",
};

const uplinkStyle = {
    position: "absolute",
    top: "5px",
    right: "5px",
    color: "white",
    fontWeight: "bold",
};

const SwitchComponent = ({ switchName, model, ports, status, role }) => {
    const oddPorts = ports.filter((_, index) => index % 2 === 0);
    const evenPorts = ports.filter((_, index) => index % 2 !== 0);

    return (
        <div className="container my-3">
            <div className="card">
                <div className="card-header bg-light d-flex justify-content-between align-items-center">
                    <div>
                        <h5 className="mb-0">Name: {switchName}</h5>
                        <p className="mb-0">Model: {model}</p>
                    </div>
                    <div>
                        <span className={`badge ${status === "Active" ? "bg-success" : "bg-secondary"}`}>Status: {status}</span>
                        <span className="ms-2 badge bg-info">Role: {role}</span>
                    </div>
                </div>
                <div className="card-body">
                    <div className="d-flex flex-wrap mb-2">
                        {oddPorts.map((port, index) => (
                            <div
                                key={index}
                                style={{ ...portStyle, backgroundColor: port.statusColor }}
                            >
                                {port.isPoE && <span style={poeStyle}>⚡</span>}
                                {port.isUplink && <span style={uplinkStyle}>↑</span>}
                                <span className="fw-bold text-white" style={{ fontSize: "0.75rem" }}>{port.number}</span>
                                <span className="text-white" style={{ fontSize: "0.6rem" }}>{port.label}</span>
                            </div>
                        ))}
                    </div>
                    <div className="d-flex flex-wrap">
                        {evenPorts.map((port, index) => (
                            <div
                                key={index}
                                style={{ ...portStyle, backgroundColor: port.statusColor }}
                            >
                                {port.isPoE && <span style={poeStyle}>⚡</span>}
                                {port.isUplink && <span style={uplinkStyle}>↑</span>}
                                <span className="fw-bold text-white" style={{ fontSize: "0.75rem" }}>{port.number}</span>
                                <span className="text-white" style={{ fontSize: "0.6rem" }}>{port.label}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const App = () => {
    const [switchData, setSwitchData] = useState(null);

    useEffect(() => {
        // Fetch data from Firebase Realtime Database
        // Simulating fetching data
        const fetchData = async () => {
            const dummyData = {
                switchName: "CASD-BIOT-L11DF-SW01",
                model: "MS225-48LP",
                status: "Active",
                role: "Member",
                ports: Array.from({ length: 48 }, (_, index) => ({
                    number: index + 1,
                    statusColor: index % 3 === 0 ? "lightgreen" : "grey",
                    isPoE: index % 5 === 0,
                    isUplink: index === 47,
                    label: index % 3 === 0 ? "100mbps" : "Disabled",
                })),
            };
            setSwitchData(dummyData);
        };

        fetchData();
    }, []);

    if (!switchData) {
        return <p>Loading switch data...</p>;
    }

    return <SwitchComponent {...switchData} />;
};

export default App;