

import React from 'react'
import ReactGA from 'react-ga'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import { AuthProvider } from "../contexts/FirebaseAuth"
import { FlashProvider } from "../contexts/FlashContext"
import ProtectedRoute from "./ProtectedRoute"

import FlashContainer from "./FlashContainer"
import TopNav from "./TopNav"
//import Footer from "./Footer"

import Home from "./Home"
import Products from "./Products"
import About from "./About"
import Login from "./GoogleLogin"
import MSLogin from "./MicrosoftLogin"
import UserProfile from "./Profile"
import AlertMe from "./AlertMe" // example page for seeing different alert styling options
import FlashForm from "./FlashForm" // example page for testing and debugging the flash
import Vision from "./Vision2"
import Properties from "./Properties"
import Viewer from "./Viewer"
import Viewer2 from "./ThreeColumn.js"
import ImportExport from "./ImportExport"
import MasterList from "./MasterList"
import TheTap from "./TheTap"
import Networks from "./Networks"
import NetworksFine from "./NetworksFine"
import KumaMetrics from "./KumaMetrics"
import MonitorManager from "./MonitorManager"
import DeviceManager from "./DeviceManager"
import LoginRedirect from "./LoginRedirect"
import Contacts from "./Contacts"
import AVRooms from "./AVRooms"
import UserList from "./UserList"
import Outages from "./Outages"
import Printers from "./Printers"
import Clients from "./Clients"
import NmapScan from "./NmapScan"
import SwitchDisplay from "./SwitchDisplay"
import UPSlist from "./UPS"
// import Maps from "./Mapping"

import 'bootstrap/dist/css/bootstrap.min.css'
import "bootswatch/dist/slate/bootstrap.min.css";
// TODO: Note: Replace ^[theme]^ (examples: darkly, slate, cosmo, spacelab, and superhero. See https://bootswatch.com/ for current theme names.)

const GA_TRACKER_ID = process.env.REACT_APP_GA_TRACKER_ID
const GA_DEBUG_MODE = (process.env.REACT_APP_GA_DEBUG_MODE === "true")
ReactGA.initialize(GA_TRACKER_ID, {debug: GA_DEBUG_MODE})

export default function App() {
    ReactGA.pageview(window.location.href)

    return (
        <AuthProvider>
        <FlashProvider>
            <div className="App">
                <TopNav/>

                <Container fluid>
                    <FlashContainer/>

                    <Router>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/about" component={About} />
                            <ProtectedRoute exact path="/networks" component={Networks} />
                            <ProtectedRoute exact path="/devices" component={NetworksFine} />
                            <ProtectedRoute exact path="/kuma" component={KumaMetrics} />
                            <ProtectedRoute exact path="/properties" component={TheTap} />
                            <ProtectedRoute exact path="/vision" component={Vision} />
                            <ProtectedRoute exact path="/nmap" component={NmapScan} />
                            <ProtectedRoute exact path="/contacts" component={Contacts} />
                            <ProtectedRoute exact path="/av-rooms" component={AVRooms} />
                            <ProtectedRoute exact path="/properties" component={Properties} />
                            <ProtectedRoute exact path="/viewer" component={Viewer} />
                            <ProtectedRoute exact path="/viewer2" component={Viewer2} />
                            <ProtectedRoute exact path="/idkfa" component={MasterList} />
                            <ProtectedRoute exact path="/iddqd" component={UserList} />
                            <ProtectedRoute exact path="/outages" component={Outages} />
                            <ProtectedRoute exact path="/exchange" component={ImportExport} />
                            <ProtectedRoute exact path="/monitors" component={MonitorManager} />
                            <ProtectedRoute exact path="/balance" component={DeviceManager} />
                            <ProtectedRoute exact path="/printers" component={Printers} />
                            <ProtectedRoute exact path="/ups" component={UPSlist} />
                            <ProtectedRoute exact path="/clients" component={Clients} />
                            <ProtectedRoute exact path="/switch" component={SwitchDisplay} />
                            {/* <ProtectedRoute exact path="/maps" component={Maps} />
                            <Route exact path="/products" component={Products} />*/}
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/login-ms" component={MSLogin} />
                            <ProtectedRoute exact path="/profile" component={UserProfile} />

                            <Route path="/alerts" component={AlertMe} />
                            <Route path="/flashes" component={FlashForm} />
                        </Switch>
                    </Router>
                </Container>

                {/* <Footer/> */}
            </div>
        </FlashProvider>
        </AuthProvider>
    )
}
