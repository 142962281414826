import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Container, Row, Col, Card, ProgressBar } from 'react-bootstrap';

const firebaseConfig = {
    // Add your Firebase configuration here
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app();
}

const UPSComponent = () => {
    const [upsDevices, setUpsDevices] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const upsRef = firebase.database().ref('ups');
        upsRef.on('value', (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setUpsDevices(Object.values(data));
            } else {
                setUpsDevices([]);
            }
            setLoading(false);
        });

        return () => {
            upsRef.off();
        };
    }, []);

    return (
        <Container fluid>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <Row>
                        <Col xs={12} md={12} lg={4} className="h4 text-left">
                            UPS Devices | Managed & Monitored
                        </Col>

                        <Col xs={12} md={12} lg={4} className="h4 text-center">
                            &nbsp;
                        </Col>

                        <Col xs={12} md={12} lg={4} className="h6 text-right text-uppercase text-info">
                            <p>Currently monitoring {upsDevices.length} UPS devices</p>
                        </Col>
                    </Row>
                    <Row>
                        {upsDevices.map((ups, index) => (
                            <Col md={4} key={index}>
                                <Card className="mb-3">
                                    <Card.Header>
                                        <strong>Name: <span className="text-white text-bold">{ups.ups_name}</span></strong><br />
                                        <Row>
                                            <Col md={6}>Model: <span className="text-white">{ups.ups_model}</span></Col>
                                            <Col md={6} className="text-right">Serial: <span className="text-white">{ups.ups_serial_number}</span></Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <h5>Battery Status</h5>
                                                <ProgressBar
                                                    now={parseInt(ups.ups_battery_capacity)}
                                                    label={`${ups.ups_battery_capacity}`}
                                                    max={100}
                                                    variant="success"
                                                    className="mb-2 text-dark"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h5>Battery Runtime</h5>
                                                <p>{ups.ups_battery_runtime}</p>
                                            </Col>
                                            <Col>
                                                <h5>Battery Replace Date</h5>
                                                <p>{ups.ups_battery_replace_date}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h5>Input Voltage</h5>
                                                <p>{ups.ups_input_voltage} V</p>
                                            </Col>
                                            <Col>
                                                <h5>Output Voltage</h5>
                                                <p>{ups.ups_output_voltage} V</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h5>Output Load</h5>
                                                <ProgressBar
                                                    now={parseInt(ups.ups_output_load)}
                                                    label={`${ups.ups_output_load}%`}
                                                    max={100}
                                                    variant="warning"
                                                    className="mb-2 text-dark"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h5>Firmware Revision</h5>
                                                <p>{ups.ups_firmware_revision}</p>
                                            </Col>
                                            <Col>
                                                <h5>SKU</h5>
                                                <p>{ups.ups_sku}</p>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Row style={{ fontSize: "0.85rem" }}>
                                            <Col md={8}>Uptime: <span className="text-white">{ups.ups_system_uptime}</span></Col>
                                            <Col md={4} className="text-right">Temperature: <span className="text-white">{ups.ups_temperature}</span></Col>
                                        </Row>
                                        <Row style={{ fontSize: "0.85rem" }}>
                                            <Col md={8}>Last Update: <span className="text-white">{ups.timestamp}</span></Col>
                                            <Col md={4} className="text-right">Battery SKU: <span className="text-white">{ups.ups_battery_sku}</span></Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </Container>
    );
};

export default UPSComponent;
